<template>
  <div class="form">
    <div class="logo">
      <logo />
    </div>
    <div class="title is-3-5 mb-1">Задать новый пароль</div>

    <div v-if="errors" class="errors is-size-7-2">
      <p
        v-for="(error, field) in errors"
        :key="field"
        class="mb-3 has-text-danger"
      >
      <span v-html="error[0]"></span>
      </p>
    </div>

    <div class="restore-form mt-2" v-if="!isSuccess">
      <form v-on:submit.prevent="setPassword">
        <tnd-input
          class="form__input"
          placeholder="Ваш пароль"
          type="password"
          v-model="password"
        >
          <template #before-input>
            <locker-icon />
          </template>
        </tnd-input>
        <button
          @click.prevent="setPassword"
          class="button is-normal main-button purple"
        >
          <span class="icon is-small">
            <locker-icon color="#FFFFFF" />
          </span>
          <span>Сохранить пароль</span>
        </button>
      </form>
      <div class="back-to-link">
        <span class="common-text">Уже есть аккаунт?&nbsp;</span>
        <router-link to="/login" class="common-link">
          Войдите в систему
        </router-link>
      </div>
    </div>
    <div class="restore-success" v-else>
      <p class="common-text">Успешно!</p>
      <p class="common-text">Новый пароль отправлен на почту.</p>
      <p class="common-text">Проверьте Вашу почту и войдите в систему.</p>
      <router-link to="/login" class="button is-normal main-button purple">
        <span class="icon is-small">
          <user-icon color="#FFFFFF" />
        </span>
        <span>Войти в систему</span>
      </router-link>
      <div class="back-to-link">
        <span class="common-text">Не зарегистрированы? &nbsp;</span>
        <router-link to="sign-up" class="common-link">
          Пройдите регистрацию
        </router-link>
      </div>
    </div>

    <router-link to="/" class="common-link form__link">
      Перейти на главную
    </router-link>
  </div>
</template>

<script>
import LockerIcon from "../icons/LockerIcon.vue";
import Logo from "../icons/Logo.vue";
import UserIcon from "../icons/UserIcon.vue";
import TndInput from "./TndInput.vue";
import authService from "../../services/auth.service";

export default {
  components: { Logo, TndInput, LockerIcon, UserIcon },
  data: () => ({
    isSuccess: false,
    password: "",
    errors: null,
  }),
  methods: {
    async setPassword() {
      this.errors = null;
      const { id, code } = this.$route.query;

      const data = await authService.setPassword(id, code, this.password);
      const { success, message, errors } = data;

      if (success) {
        this.$buefy.toast.open({
          message: message,
          type: "is-success",
          duration: 8000,
        });

        this.$router.push({ name: "login" });
      } else {
        this.errors = errors;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .restore-form,
  .restore-success {
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-top: 75px;
  }

  &__input:first {
    margin-top: 28px;
  }

  &__input + &__input {
    margin-top: 6px;
  }

  .button {
    margin-top: 2em;
  }

  .back-to-link {
    display: flex;
    margin-top: 29px;
  }

  &__link {
    margin-top: 77px;
  }
}
</style>
